import React, { useState, useEffect } from "react"
import { Input, Form, Select, Button } from "antd"
import styles from "./index.module.scss"

const { Option } = Select

const NewMonth = ({
  forceMonthsUpdate,
  setForceMonthsUpdate,
  selectedItem,
  setModal,
}) => {
  const [name, setName] = useState()

  const [hours, setHours] = useState("")
  const [minutes, setMinutes] = useState("")

  const [day, setDay] = useState("")
  const [month, setMonth] = useState()
  const [year, setYear] = useState("")

  const [error, setError] = useState("")
  const [formDisabled, setFormDisabled] = useState(true)

  useEffect(() => {
    setFormDisabled(true)
  }, [selectedItem])

  useEffect(() => {
    validateSubmit()
  }, [name, hours, minutes, day, month, year])

  const validateSubmit = () => {
    if (name && hours && minutes && day && month && year) {
      setFormDisabled(false)
    } else {
      setFormDisabled(true)
    }
  }

  const onCreate = () => {
    const monthObject = {
      name,
      deadline: `${month} ${day} ${year} ${hours}:${minutes}:00 GMT-0300 (Argentina Standard Time)`,
    }
    console.log(monthObject)
    updateItem(monthObject, setModal)
  }

  // const api = "http://localhost:8080/api"
   const api = "https://wacopaamericamotmback-prd.azurewebsites.net/api"

  const updateItem = (month) => {
    fetch(`${api}/balondepotrero/months/create`, {
      method: "POST",
      body: JSON.stringify(month),
      headers: {
        "Content-Type": "application/json",
      },
    }).then(() => {
      setForceMonthsUpdate(!forceMonthsUpdate)
      setModal(false)
    })
  }

  return (
    <div>
      <div className={`${styles.pageHeader} ${styles.modalHeader}`}>
        <span className={styles.title}>Editar mes</span>
      </div>
      <div className={styles.modalRow}>
        <div className={styles.modalInputContainer}>
          <p className={styles.modalInputLabel}>
            MES EN QUE SE JUGARON LOS PARTIDOS
          </p>
          <Form.Item>
            <Select
              value={name}
              placeholder="Elegí un mes"
              onChange={(value) => setName(value)}
            >
              <Option value="enero">enero</Option>
              <Option value="febrero">febrero</Option>
              <Option value="marzo">marzo</Option>
              <Option value="abril">abril</Option>
              <Option value="mayo">mayo</Option>
              <Option value="junio">junio</Option>
              <Option value="julio">julio</Option>
              <Option value="agosto">agosto</Option>
              <Option value="septiembre">septiembre</Option>
              <Option value="octubre">octubre</Option>
              <Option value="noviembre">noviembre</Option>
              <Option value="diciembre">diciembre</Option>
            </Select>
          </Form.Item>
        </div>
      </div>
      <div className={styles.modalRow}>
        <p className={styles.modalInputLabel}>FECHA DE CIERRE DE LA VOTACIÓN</p>
        <div className={styles.modalInputContainer}>
          <p className={styles.modalInputLabel}>DÍA</p>
          <Form.Item>
            <Input value={day} onChange={(e) => setDay(e.target.value)} />
          </Form.Item>
        </div>
        <div className={`${styles.modalInputContainer} ${styles.lastInput}`}>
          <p className={styles.modalInputLabel}>MES</p>
          <Form.Item>
            <Select
              value={month}
              placeholder="Elegí un mes"
              onChange={(value) => setMonth(value)}
            >
              <Option value="Jan">enero</Option>
              <Option value="Feb">febrero</Option>
              <Option value="Mar">marzo</Option>
              <Option value="Apr">abril</Option>
              <Option value="May">mayo</Option>
              <Option value="Jun">junio</Option>
              <Option value="Jul">julio</Option>
              <Option value="Aug">agosto</Option>
              <Option value="Sep">septiembre</Option>
              <Option value="Oct">octubre</Option>
              <Option value="Nov">noviembre</Option>
              <Option value="Dec">diciembre</Option>
            </Select>
          </Form.Item>
        </div>
      </div>
      <div className={styles.modalRow}>
        <div className={styles.modalInputContainer}>
          <p className={styles.modalInputLabel}>AÑO</p>
          <Form.Item>
            <Input value={year} onChange={(e) => setYear(e.target.value)} />
          </Form.Item>
        </div>
        <div className={`${styles.modalInputContainer} ${styles.lastInput}`}>
          <p className={styles.modalInputLabel}>HORAS</p>
          <Form.Item>
            <Input value={hours} onChange={(e) => setHours(e.target.value)} />
          </Form.Item>
        </div>
      </div>
      <div className={styles.modalRow}>
        <div className={styles.modalInputContainer}>
          <p className={styles.modalInputLabel}>MINUTOS</p>
          <Form.Item>
            <Input
              value={minutes}
              onChange={(e) => setMinutes(e.target.value)}
            />
          </Form.Item>
        </div>
      </div>

      {/* error */}
      <div className={"ContainerMessageCantSlides"}>
        <p className={"messageCantSlide"}>{error}</p>
      </div>

      <Button
        onClick={onCreate}
        disabled={formDisabled}
        className={"sendButton"}
        type="primary"
        block
      >
        CREAR
      </Button>
    </div>
  )
}

export default NewMonth
