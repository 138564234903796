import styles from "./index.module.scss"
import TablaTrivias from "./tablaTrivias"
import TablaRanking from "./tablaRanking"
import React, { useState, useEffect } from "react"
import { Layout, Menu, Form, Input, Button, Modal } from "antd"
import { CloseCircleOutlined } from "@ant-design/icons"
import NewTrivia from "../trivia-new"

const Trivias = ({ handleLogout }) => {
  const [allTrivias, setAllTrivias] = useState([])
  const [triviaId, setTriviaId] = useState("")
  const [newTrivia, setNewTrivia] = useState(false)

  // const api = "http://localhost:8080/api"
   const api = "https://wacopaamericamotmback-prd.azurewebsites.net/api"
  const token = "?token=copaamerica2021"

  useEffect(() => {
    //conexion con base de datos para traer las trivias.
    fetch(`${api}/trivias/cards`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(async (response) => {
      if (response.ok) {
        let data = await response.json()
        setAllTrivias(data)
      } else {
        console.error("Error guardando los datos. \n", response)
      }
    })
  }, [])

  const handleNewTrivia = ()=>{
    setNewTrivia(!newTrivia)
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h1 className={styles.title}>{`TRIVIAS${
          triviaId && " - " + triviaId
        }`}</h1>
        <button onClick={handleNewTrivia}>Agregar trivia +</button>
        {triviaId !== "" ? (
          <button
            style={{ height: "5vh", alignItems: "flex-end" }}
            onClick={() => {
              setTriviaId("")
            }}
          >
            {" "}
            VOLVER{" "}
          </button>
        ) : null}
      </div>
      {triviaId === "" ? (
        newTrivia ?  (
          <Modal
          centered
          closeIcon={<CloseCircleOutlined style={{ fontSize: "2rem" }} />}
          visible={newTrivia}
          onOk={handleNewTrivia}
          onCancel={handleNewTrivia}
          footer={null}
          width={900}
          wrapClassName={"wrapContainerModal"}
        >
          {/* <NewPlayer
            months={months}
            selectedItem={selectedItem}
            visible={showNewPlayerModal}
            setModal={setShowNewPlayerModal}
            setForcePlayersUpdate={setForcePlayersUpdate}
            forcePlayersUpdate={forcePlayersUpdate}
          /> */}
          <NewTrivia handleNewTrivia={handleNewTrivia}/>
        </Modal>
        ) : <TablaTrivias setTriviaId={setTriviaId} /> 
      ) : (
        <TablaRanking triviaId={triviaId} setTriviaId={setTriviaId} />
      )}
    </div>
  )
}

export default Trivias
