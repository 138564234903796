import React, { useState, useEffect } from "react";
import { Input, Form, Select, Upload, Modal, Button, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import styles from "./index.module.scss";
import firebase from "../../firebase.js";

const { Option } = Select;

const EditPlayer = ({
  forcePlayersUpdate,
  setForcePlayersUpdate,
  selectedItem,
  setModal,
  months,
}) => {
  const [idbpplayers, setIdbpplayers] = useState("");
  const [name, setName] = useState("");
  // const [position, setPosition] = useState("");
  // const [matchday, setMatchday] = useState("");
  const [monthName, setMonthName] = useState("");
  const [monthId, setMonthId] = useState("");
  const [image, setImage] = useState("");

  const [imageInfo, setImageInfo] = useState([]);
  const [previewImage, setPreviewImage] = useState("");
  const [visibleImagePreview, setVisibleImagePreview] = useState(false);

  const [error, setError] = useState("");
  const [formDisabled, setFormDisabled] = useState(true);

  useEffect(() => {
    loadData(selectedItem);
    setFormDisabled(true);
  }, [selectedItem]);

  useEffect(() => {
    validateSubmit();
  }, [idbpplayers, name, monthName, monthId, image]);

  const loadData = (element) => {
    setIdbpplayers(element.idbpmonthplayers);
    setName(element.name);
    setMonthName(element.monthName);
    setMonthId(element.monthId);
    setImage(element.image);
    setImageInfo([
      {
        url: element.image,
      },
    ]);
  };

  const validateSubmit = () => {
    if (name && monthId && imageInfo.length > 0) {
      setFormDisabled(false);
    } else {
      setFormDisabled(true);
    }
  };

  const onEdit = () => {
    const player = {
      idbpplayers,
      name,
      monthId,
      image: imageInfo[0].xhr || image,
    };
    updateItem(player, setModal);
  };

  // upload images
  const handlerImage = ({ file }) => {
    if (file.status === "removed") {
      // remove
      setImageInfo([]);
    } else {
      //load image
      loadImageInfo(file, setImageInfo, setError);
    }
  };

  const handlePreview = async (file) => {
    setPreviewImage(file.xhr);
    setVisibleImagePreview(true);
  };

  // const api = "http://localhost:8080/api";
   const api = "https://wacopaamericamotmback-prd.azurewebsites.net/api"

  const updateItem = (player) => {
    fetch(`${api}/balondepotrero/monthlyplayers/${player.idbpplayers}/update`, {
      method: "PUT",
      body: JSON.stringify(player),
      headers: {
        "Content-Type": "application/json",
      },
    }).then(() => {
      setForcePlayersUpdate(!forcePlayersUpdate);
      setModal(false);
    });
  };

  const customUpload = (event, folder) => {
    var imagesRef = firebase.storage().ref().child(folder);
    var fileName = new Date().getTime().toString();
    var fileRef = imagesRef.child(fileName);
    fileRef
      .put(event.file)
      .then(function (snapshot) {
        fileRef.getDownloadURL().then(function (url) {
          event.onSuccess(null, url);
        });
      })
      .catch(function (err) {
        event.onError(err);
      });
  };

  const beforeUpload = (file) => {
    const type = file.type === "image/jpeg" || file.type === "image/png";
    if (!type) {
      message.error("You can only upload JPG/PNG file!");
      return false;
    }
    const size = file.size / 1024 / 1024 < 1;
    if (!size) {
      message.error("Image must smaller than 1MB!");
      return false;
    }
    return true;
  };

  const loadImageInfo = (file, setImageInfo, setError) => {
    if (file.status != "removed") {
      if (beforeUpload(file)) {
        //check type and size image
        setImageInfo([file]);
        setError("");
      } else {
        setError("La imagen cargada es mayor a 1MB");
      }
    }
  };

  return (
    <div>
      <div className={`${styles.pageHeader} ${styles.modalHeader}`}>
        <span className={styles.title}>Editar jugador</span>
      </div>
      <div className={styles.modalRow}>
        <div className={styles.modalInputContainer}>
          <p className={styles.modalInputLabel}>NOMBRE</p>
          <Form.Item>
            <Input value={name} onChange={(e) => setName(e.target.value)} />
          </Form.Item>
        </div>

        <div className={`${styles.modalInputContainer} ${styles.lastInput}`}>
          <p className={styles.modalInputLabel}>MES</p>
          <Form.Item>
            <Select
              value={monthId}
              placeholder="Elegí el mes"
              onChange={(value) => setMonthId(value)}
            >
              {months.map((month, index) => {
                 const fecha = new Date(month.deadline);
                 const year = fecha.getFullYear();
                return(
                <Option key={index} value={month.idbpmonths}>
                  {month.name} {year}
                </Option>
              )})}
            </Select>
          </Form.Item>
        </div>
      </div>

      {/* images */}
      <div className={styles.modalRow}>
        {/* imageDesktop */}
        <div className={styles.modalInputContainer}>
          <p className={styles.modalInputLabel}>IMAGEN</p>
          <Form.Item>
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              fileList={imageInfo}
              onChange={(e) => handlerImage(e)}
              customRequest={(e) => customUpload(e, "players")}
              onPreview={handlePreview}
            >
              {!imageInfo.length > 0 && <PlusOutlined />}
            </Upload>
          </Form.Item>
        </div>
        {/* modal view image preview */}
        <Modal
          wrapClassName={"wrapContainerModalPreview"}
          visible={visibleImagePreview}
          footer={null}
          onCancel={(e) => setVisibleImagePreview(false)}
        >
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </Modal>
      </div>

      {/* error */}
      <div className={"ContainerMessageCantSlides"}>
        <p className={"messageCantSlide"}>{error}</p>
      </div>

      <Button
        onClick={onEdit}
        disabled={formDisabled}
        className={"sendButton"}
        type="primary"
        block
      >
        EDITAR
      </Button>
    </div>
  );
};

export default EditPlayer;
