import styles from "./index.module.scss";
import React, { useState, useEffect } from "react";
import { Table, Button, Switch, message } from "antd";

const Players = ({
  forcePlayersUpdate,
  setForcePlayersUpdate,
  setSelectedItem,
  setShowEditPlayerModal,
  setShowNewPlayerModal,
}) => {
  const [players, setPlayers] = useState([]);

  // const successMessage = () => {
  //   message.success("¡Jugador modificado con éxito!");
  // };

  const toggleWinner = (id, winner) => {
    if (winner)
      fetch(`${api}/balondepotrero/monthwinners/${id}/remove`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }).then(() => {
        setForcePlayersUpdate(!forcePlayersUpdate);
      });
    else
      fetch(`${api}/balondepotrero/monthwinners/${id}/add`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }).then(() => {
        setForcePlayersUpdate(!forcePlayersUpdate);
      });
  };

  const columns = [
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Mes",
      dataIndex: "monthName",
      key: "monthName",
      render: (text, record) => {
        return <p style={{ textTransform: "uppercase" }}>{record.monthName}</p>;
      },
    },
    {
      title: "Votos",
      dataIndex: "votes",
      key: "votes",
    },
    {
      title: "Ganador",
      dataIndex: "winner",
      key: "winner",
      render: (text, record) => (
        <Switch
          checked={record.winner}
          onChange={() => toggleWinner(record.idbpmonthplayers, record.winner)}
        />
      ),
    },
    {
      title: "Imagen",
      dataIndex: "image",
      key: "image",
      render: (text, record) => (
        <img
          style={{
            height: "80px",
          }}
          src={`${record.image}`}
        />
      ),
    },
    {
      title: "Editar",
      key: "edit",
      render: (text, record) => {
        return (
          <button
            onClick={() => {
              setSelectedItem(record);
              setShowEditPlayerModal(true);
            }}
          >
            Editar{" "}
          </button>
        );
      },
    },
  ];

  // const api = "http://localhost:8080/api";
   const api = "https://wacopaamericamotmback-prd.azurewebsites.net/api"
  const token = "?token=copaamerica2021";

  useEffect(() => {
    fetch(`${api}/balondepotrero/monthlyplayers/all`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(async (response) => {
      if (response.ok) {
        let data = await response.json()
        setPlayers(data);
      } else {
        console.error("Error guardando los datos. \n", response);
      }
    });
  }, [forcePlayersUpdate]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h1 className={styles.title}>Jugadores</h1>
        <Button
          style={{
            background: "none",
            border: "1px solid #9146F7",
          }}
          onClick={() => {
            setShowNewPlayerModal(true);
          }}
        >
          NUEVO
        </Button>
      </div>

      <Table dataSource={players} columns={columns} size={"small"} />
    </div>
  );
};

export default Players;
