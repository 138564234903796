import React, { useState, useEffect } from "react";
import { Table } from "ant-table-extensions";

const TablaRanking = ({ triviaId }) => {
  const [allPuntajes, setAllPuntajes] = useState([]);
  console.log("ALL PUNTAJES ",allPuntajes)
  const columns = [
    {
      title: "ID TRIVIA",
      dataIndex: "triviaId",
      key: "triviaId",
      width:150
    },
    {
      title: "USUARIO ID",
      dataIndex: "userId",
      key: "userId",
      width:200
    },
    {
      title: "USUARIO",
      dataIndex: "name",
      key: "name",
      width:200
    },
    {
      title: "PUNTOS",
      dataIndex: "puntos",
      key: "puntos",
      // width:100
    },
    // {
    //   title: "Estado",
    //   dataIndex: "active",
    //   key: "active",
    //   render: (text, record) => {
    //     return <p> {text === "1" ? "Activo" : "Inactivo"}</p>;
    //   },
    // },
  ];

  // const api = "http://localhost:8080/api";
  const api = "https://wacopaamericamotmback-prd.azurewebsites.net/api"

  useEffect(() => {
    //conexion con base de datos para traer las trivias.
    fetch(`${api}/puntajes/dash/${triviaId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(async (response) => {
      if (response.ok) {
        let data = await response.json();
        console.log("DATAAA",data)
        setAllPuntajes(data);
      } else {
        console.error("Error guardando los datos. \n", response);
      }
    });
  }, []);

  // const updateState = (partido, id) => {
  //   fetch(`${api}/fixture/`, {
  //     method: "POST",
  //     body: JSON.stringify({
  //       active: partido,
  //       idfixture: id,
  //     }),
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   }).then(() => {
  //     console.log("UPDATE HECHOO");
  //   });
  // };

  return (
    <Table
      dataSource={allPuntajes}
      columns={columns}
      exportable
      searchable
      exportableProps={{ showColumnPicker: true }}
    />
  );
};

export default TablaRanking;
