import React from "react";
import { Table, Button } from "antd";
import styles from "./index.module.scss";

const Months = ({
  months,
  setSelectedItem,
  setShowNewMonthModal,
  setShowEditMonthModal,
}) => {
  const columns = [
    {
      title: "ID",
      dataIndex: "idbpmonths",
      key: "idbpmonths",
    },
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        const fecha = new Date(record.deadline);
        const year = fecha.getFullYear();
        return (
          <p>
            {record.name} {year}
          </p>
        );
      },
    },
    {
      title: "Cierre de votación",
      dataIndex: "deadline",
      key: "deadline",
    },
    {
      title: "Editar",
      key: "edit",
      render: (text, record) => {
        return (
          <button
            onClick={() => {
              setSelectedItem(record);
              setShowEditMonthModal(true);
            }}
          >
            Editar{" "}
          </button>
        );
      },
    },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h1 className={styles.title}>MESES</h1>
        <Button
          style={{
            background: "none",
            border: "1px solid #9146F7",
          }}
          onClick={() => {
            setShowNewMonthModal(true);
          }}
        >
          NUEVO
        </Button>
      </div>

      <Table dataSource={months} columns={columns} size={"small"} />
    </div>
  );
};

export default Months;
