// "use strict"
import { excel2json } from "js2excel"
import React, { useState, useEffect } from "react"
import { Table, Switch, Upload, Button, message } from "antd"
import { UploadOutlined } from "@ant-design/icons"
import firebase from "../../../firebase.js"

const TablaPartidos = ({ triviaId, setTriviaId }) => {
  const [allTrivias, setAllTrivias] = useState([])
  const [forceRefresh, setForceRefresh] = useState(false)

  const success = () => {
    message.success("Estado modificado con éxito!")
  }

  const submitExcelWithQuestions = async (event, id) => {
    // event.preventDefault()
    console.log(event.file)
    try {
      await excel2json(
        [event.file],
        async (data) => {
          const questions = Object.values(data)[0]
          console.log(questions)
          let response = await fetch(`${api}/trivias/questions/${id}`, {
            method: "POST",
            body: JSON.stringify(questions),
            headers: {
              "Content-Type": "application/json",
            },
          })
          if (response.ok) {
            message.success("Preguntas cargadas con éxito")
          } else {
            message.error("Error al cargar el excel")
          }
        },
        ""
      )
    } catch (error) {
      console.log(error)
    }
  }

  const beforeUpload = (file) => {
    const type = file.type === "image/jpeg" || file.type === "image/png"
    if (!type) {
      message.error("You can only upload JPG/PNG file!")
      return false
    }
    const size = file.size / 1024 / 1024 < 1
    if (!size) {
      message.error("Image must smaller than 1MB!")
      return false
    }
    return true
  }

  const customUpload = (event, folder, id) => {
    var imagesRef = firebase.storage().ref().child(folder)
    var fileName = new Date().getTime().toString()
    var fileRef = imagesRef.child(fileName)
    message.success("Subiendo imagen, espere por favor")
    fileRef
      .put(event.file)
      .then(function (snapshot) {
        fileRef.getDownloadURL().then(function (url) {
          updateImage({ image: url }, id, event)
        })
      })
      .catch(function (err) {
        message.error("Error al subir la imagen")
        event.onError(err)
      })
  }

  const updateImage = (imageObject, triviaId, event) => {
    fetch(`${api}/trivias/update/image/${triviaId}`, {
      method: "POST",
      body: JSON.stringify(imageObject),
      headers: {
        "Content-Type": "application/json",
      },
    }).then(() => {
      event.onSuccess(null)
      setForceRefresh(!forceRefresh)
    })
  }

  const columns = [
    {
      title: "id Trivia",
      dataIndex: "idtrivias",
      key: "idtrivias",
    },
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Fecha del Sorteo",
      dataIndex: "fechaSorteo",
      key: "fechaSorteo",
    },
    {
      title: "Activo",
      dataIndex: "active",
      key: "active",
      render: (text, record) => {
        return (
          <Switch
            checked={Number(text)}
            onChange={() => {
              updateState(text === "1" ? "0" : "1", record.idtrivias)
              success()
            }}
          />
        )
      },
    },
    {
      title: "Finalizada",
      dataIndex: "finished",
      key: "finished",
      render: (text, record) => {
        return (
          <Switch
            checked={Number(text)}
            onChange={() => {
              updateFinished(text === "1" ? "0" : "1", record.idtrivias)
              success()
            }}
          />
        )
      },
    },
    {
      title: "Ranking",
      key: "action",
      render: (text, record) => {
        return (
          <button
            onClick={() => {
              setTriviaId(text.idtrivias)
            }}
          >
            VER
          </button>
        )
      },
    },
    {
      title: "Imagen",
      dataIndex: "image",
      key: "image",
      render: (text, record) => (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {record.image ? (
            <img
              style={{
                height: "80px",
                margin: "0 auto",
              }}
              src={`${record.image}`}
            />
          ) : (
            <div>Sin Imagen</div>
          )}
          <Upload
            showUploadList={false}
            accept=".jpg, .jpeg, .png, .gif"
            customRequest={(event) => {
              customUpload(event, "trivias", record.idtrivias)
            }}
          >
            <Button icon={<UploadOutlined />}>
              {record.image ? "CAMBIAR" : "AGREGAR"}
            </Button>
          </Upload>
        </div>
      ),
    },
    {
      title: "Preguntas",
      key: "action",
      render: (text, record) => {
        return (
          <Upload
            showUploadList={false}
            accept=".xls, .xlsx"
            customRequest={(event) =>{
              message.success("Cargando preguntas, espere por favor, suele tardar 30 segundos...")
              submitExcelWithQuestions(event, record.idtrivias)
            }}
          >
            <Button icon={<UploadOutlined />}>Cargar</Button>
          </Upload>
        )
      },
    },
    {
      title: "Visible",
      dataIndex: "visible",
      key: "visible",
      render: (text, record) => {
        return (
          <Switch
            checked={Number(text)}
            onChange={() => {
              updateVisible(text === 1 ? 0 : 1, record.idtrivias)
              success()
            }}
          />
        )
      },
    }
  ]

  // const api = "http://localhost:8080/api"
   const api = "https://wacopaamericamotmback-prd.azurewebsites.net/api"
  const token = "?token=copaamerica2021"

  useEffect(() => {
    //conexion con base de datos para traer las trivias.
    fetch(`${api}/trivias/cards`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(async (response) => {
      if (response.ok) {
        let data = await response.json()
        setAllTrivias(data)
      } else {
        console.error("Error guardando los datos. \n", response)
      }
    })
  }, [allTrivias, forceRefresh])

  const updateState = (partido, id) => {
    fetch(`${api}/trivias/update`, {
      method: "POST",
      body: JSON.stringify({
        active: partido,
        idtrivias: id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(() => {
        console.log("Update done!")
      })
      .then(() => {
        fetch(`${api}/trivias/cards`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }).then(async (response) => {
          if (response.ok) {
            let data = await response.json()
            setAllTrivias(data)
          } else {
            console.error("Error guardando los datos. \n", response)
          }
        })
      })
  }

  //FINALIZAR TRIVIA

  const updateFinished = (partido, id) => {
    fetch(`${api}/trivias/update/finished`, {
      method: "POST",
      body: JSON.stringify({
        finished: partido,
        idtrivias: id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }).then(() => {
      console.log("Update done!")
      setAllTrivias(allTrivias)
    })
  }

  //OCULTAR TRIVIA

  const updateVisible = (visible, id) => {
    fetch(`${api}/trivias/update/visible`, {
      method: "POST",
      body: JSON.stringify({
        visible: visible,
        idtrivias: id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }).then(() => {
      console.log("Update done!")
      setAllTrivias(allTrivias)
    })
  }

  return <Table dataSource={allTrivias} columns={columns} size={"medium"} />
}

export default TablaPartidos
