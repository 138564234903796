import React, { useState, useEffect } from "react";
import { Button } from "antd";
import styles from "./index.module.scss";
import firebase from "../../firebase.js";

const SuccessText = ({ setEditSuccess, setSelectItem }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    let db = firebase.firestore();
    let listener = db.collection("success-text").onSnapshot((snap) => {
      let arr = [];
      snap.forEach((item) => {
        let itemData = item.data();
        itemData.id = item.id;
        arr.push(itemData);
      });
      setData(arr);
    });
    return () => listener();
  }, []);

  return (
    <div>
      <div className={styles.container}>
        <h1 className={styles.title}>Texto del Success</h1>
      </div>
      <div style={{ background: "white", padding: "30px" }}>
        <h4 style={{ fontSize: "20px", fontWeight: 700, margin: 0 }}>
          {" "}
          1er Texto:
        </h4>
        <p>{data.length > 0 && data[0].normalText} </p>
        <h4 style={{ fontSize: "20px", fontWeight: 700, margin: 0 }}>
          {" "}
          2do Texto (en negrita):
        </h4>
        <p>{data.length > 0 && data[0].goldText} </p>
       
 

        <h4 style={{ fontSize: "20px", fontWeight: 700, margin: 0 }}>
          {" "}
          3er Texto:
        </h4>
        <p>{data.length > 0 && data[0].who} </p>

        <h4 style={{ fontSize: "20px", fontWeight: 700, margin: 0 }}> Link:</h4>
        <p>{data.length > 0 && data[0].link} </p>
        
        <div style={{  paddingTop: "10px" }}>
          <Button
            className={styles.button}
            onClick={() => {
              setEditSuccess(true);
              setSelectItem(data[0]);
            }}
          >
            EDITAR
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SuccessText;
