import React, { useState, useEffect } from "react"
import { Input, Form, Select, Upload, Modal, Button, message } from "antd"
import { PlusOutlined } from "@ant-design/icons"
import styles from "./index.module.scss"
import firebase from "../../firebase.js"

const { Option } = Select

const NewTrivia = ({handleNewTrivia}) => {
  const [idbpplayers, setIdbpplayers] = useState("")
  const [name, setName] = useState("")
  const [date, setDate] = useState("")
  const [fechaSorteo, setFechaSorteo] = useState("")
  const [premio, setPremio] = useState("")
  const [availableDate, setAvailableDate] = useState("")
  const [image, setImage] = useState("")
  const [imageInfo, setImageInfo] = useState([])
  const [previewImage, setPreviewImage] = useState("")
  const [visibleImagePreview, setVisibleImagePreview] = useState(false)

  const [error, setError] = useState("")
  const [formDisabled, setFormDisabled] = useState(true)

  // useEffect(() => {
  //   setFormDisabled(true)
  // }, [selectedItem])

  useEffect(() => {
    validateSubmit()
    console.log(name,date,availableDate,premio,fechaSorteo,imageInfo.length)
  }, [
    name,
    date,
    availableDate,
    fechaSorteo,
    premio,
    imageInfo,
  ])

  const validateSubmit = () => {
    if (
      name &&
      date &&
      availableDate &&
      premio
      && imageInfo.length > 0
    ) {
      setFormDisabled(false)
    } else {
      setFormDisabled(true)
    }
  }

  const onCreate = () => {
    const trivia = {
      name,
      premio,
      date,
      availableDate,
      fechaSorteo,
      image: imageInfo[0].xhr,
    }
    createItem(trivia, handleNewTrivia)
  }

  // upload images
  const handlerImage = ({ file }) => {
    if (file.status === "removed") {
      // remove
      setImageInfo([])
    } else {
      //load image
      loadImageInfo(file, setImageInfo, setError)
    }
  }

  const handlePreview = async (file) => {
    setPreviewImage(file.xhr)
    setVisibleImagePreview(true)
  }

  // const api = "http://localhost:8080/api"
   const api = "https://wacopaamericamotmback-prd.azurewebsites.net/api"

  const createItem = (trivia) => {
    console.log("Esta es la nueva trivia",trivia)
    fetch(`${api}/trivias`, {
      method: "POST",
      body: JSON.stringify(trivia),
      headers: {
        "Content-Type": "application/json",
      },
    }).then(() => {
      // setForcePlayersUpdate(!forcePlayersUpdate)
      // // setModal(false)
      handleNewTrivia(false)
    })
  }

  const customUpload = (event, folder) => {
    var imagesRef = firebase.storage().ref().child(folder)
    var fileName = new Date().getTime().toString()
    var fileRef = imagesRef.child(fileName)
    fileRef
      .put(event.file)
      .then(function (snapshot) {
        fileRef.getDownloadURL().then(function (url) {
          event.onSuccess(null, url)
        })
      })
      .catch(function (err) {
        event.onError(err)
      })
  }

  const beforeUpload = (file) => {
    const type = file.type === "image/jpeg" || file.type === "image/png"
    if (!type) {
      message.error("You can only upload JPG/PNG file!")
      return false
    }
    const size = file.size / 1024 / 1024 < 1
    if (!size) {
      message.error("Image must smaller than 1MB!")
      return false
    }
    return true
  }

  const loadImageInfo = (file, setImageInfo, setError) => {
    if (file.status != "removed") {
      if (beforeUpload(file)) {
        //check type and size image
        setImageInfo([file])
        setError("")
      } else {
        setError("La imagen cargada es mayor a 1MB")
      }
    }
  }

  return (
    <div>
      <div className={`${styles.pageHeader} ${styles.modalHeader}`}>
        <span className={styles.title}>Crear trivia</span>
      </div>
      <div className={styles.modalRow}>
        <div className={styles.modalInputContainer}>
          <p className={styles.modalInputLabel}>NOMBRE</p>
          <Form.Item>
            <Input value={name} onChange={(e) => setName(e.target.value)} />
          </Form.Item>
        </div>
        <div className={`${styles.modalInputContainer} ${styles.lastInput}`}>
          <p className={styles.modalInputLabel}>Fecha</p>
          <Form.Item>
            <Input value={date} onChange={(e) => setDate(e.target.value)} />
          </Form.Item>
        </div>
      </div>
      <div className={styles.modalRow}>
        <div className={styles.modalInputContainer}>
          <p className={styles.modalInputLabel}>Fecha de Sorteo</p>
          <Form.Item>
            <Input value={fechaSorteo} onChange={(e) => setFechaSorteo(e.target.value)} />
          </Form.Item>
        </div>
        <div className={`${styles.modalInputContainer} ${styles.lastInput}`}>
          <p className={styles.modalInputLabel}>Desde</p>
          <Form.Item>
            <Input value={availableDate} onChange={(e) => setAvailableDate(e.target.value)} />
          </Form.Item>
        </div>
      </div>
      <div className={styles.modalRow}>
        <div className={styles.modalInputContainer}>
          <p className={styles.modalInputLabel}>Premio</p>
          <Form.Item>
            <Input value={premio} onChange={(e) => setPremio(e.target.value)} />
          </Form.Item>
        </div>
      </div>

      {/* images */}
      <div className={styles.modalRow}>
        {/* imageDesktop */}
        <div className={styles.modalInputContainer}>
          <p className={styles.modalInputLabel}>IMAGEN</p>
          <Form.Item>
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              fileList={imageInfo}
              onChange={(e) => handlerImage(e)}
              customRequest={(e) => customUpload(e, "players")}
              onPreview={handlePreview}
            >
              {!imageInfo.length > 0 && <PlusOutlined />}
            </Upload>
          </Form.Item>
        </div>
        {/* modal view image preview */}
        <Modal
          wrapClassName={"wrapContainerModalPreview"}
          visible={visibleImagePreview}
          footer={null}
          onCancel={(e) => setVisibleImagePreview(false)}
        >
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </Modal>
      </div>

      {/* error */}
      <div className={"ContainerMessageCantSlides"}>
        <p className={"messageCantSlide"}>{error}</p>
      </div>

      <Button
        onClick={onCreate}
        disabled={formDisabled}
        className={"sendButton"}
        type="primary"
        block
      >
        CREAR
      </Button>
    </div>
  )
}

export default NewTrivia
