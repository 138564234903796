import firebase from "firebase";

const firebaseConfig = {
    apiKey: "AIzaSyDNXVovolGaNfoDIZeT4rqqCp_dUegCl9w",
    authDomain: "copaamericadash.firebaseapp.com",
    projectId: "copaamericadash",
    storageBucket: "copaamericadash.appspot.com",
    messagingSenderId: "991546854866",
    appId: "1:991546854866:web:7410e08d9fb9480ceb8c0b",
    measurementId: "G-K10YHXPGWB",
}

firebase.initializeApp(firebaseConfig)

export default firebase;