import { Layout, Menu, Form, Input, Button, Modal } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import LogoQuilmes from "./assets/icons/logo_quilmes.svg";
import LogoItesa from "./assets/icons/logo_itesa.svg";
import React, { useState, useEffect } from "react";
import Trivias from "./views/trivias";
import Months from "./views/months";
import Players from "./views/players";
import EditMonth from "./views/months-edit";
import NewMonth from "./views/months-new";
import EditPlayer from "./views/players-edit";
import NewPlayer from "./views/players-new";
import EditSuccess from "./views/success-edit";

import firebase from "./firebase";

import SuccessText from "./views/success";

const { Header, Content, Sider } = Layout;
const { SubMenu } = Menu;

const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const layoutLogin = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const App = () => {
  // const [trivia, setTrivia] = useState(true)
  // const [preguntas, setPreguntas] = useState(false)
  const [isUser, setIsUser] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [incorrect, setIncorrect] = useState(false);
  const [openMenuCategory, setOpenMenuCategory] = useState("trivias");
  const [selectedItem, setSelectedItem] = useState("trivias");

  const [showEditMonthModal, setShowEditMonthModal] = useState(false);
  const [showEditPlayerModal, setShowEditPlayerModal] = useState(false);

  const [showNewMonthModal, setShowNewMonthModal] = useState(false);
  const [showNewPlayerModal, setShowNewPlayerModal] = useState(false);

  const [forceMonthsUpdate, setForceMonthsUpdate] = useState(false);
  const [forcePlayersUpdate, setForcePlayersUpdate] = useState(false);

  const [months, setMonths] = useState([]);

  const [editSuccess, setEditSuccess] = useState(false);
  const [selectItem, setSelectItem] = useState({});

  // const api = "http://localhost:8080/api"
  const api = "https://wacopaamericamotmback-prd.azurewebsites.net/api";

  useEffect(() => {
    fetch(`${api}/balondepotrero/months`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(async (response) => {
      if (response.ok) {
        let data = await response.json();
        setMonths(data);
      } else {
        console.error("Error guardando los datos. \n", response);
      }
    });
  }, [forceMonthsUpdate]);

  const handleClick = (e) => {
    setOpenMenuCategory(e.key);
  };

  function handleSubmit() {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        // Signed in
        var user = userCredential.user;
        setIsUser(true);
        // ...
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        setIncorrect(true);
      });
  }

  function handleLogout() {
    firebase
      .auth()
      .signOut()
      .then(() => {
        setIsUser(false);
      })
      .catch((error) => {
        // An error happened.
        console.log("ERROR");
      });
  }

  return !isUser ? (
    <Form
      {...layoutLogin}
      name="basic"
      initialValues={{
        remember: true,
      }}
      onFinish={handleSubmit}
      //   onFinishFailed={onFinishFailed}
      //   onSubmit={()=>handleSubmit}
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img
        src={LogoItesa}
        style={{ width: "40vh", margin: "10vh" }}
        alt="Logo de Itesa"
      />

      <Form.Item
        label="Username"
        name="username"
        rules={[
          {
            required: true,
            message: "Please input your username!",
          },
        ]}
      >
        <Input onChange={(e) => setEmail(e.target.value)} />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[
          {
            required: true,
            message: "Please input your password!",
          },
        ]}
      >
        <Input.Password onChange={(e) => setPassword(e.target.value)} />
      </Form.Item>

      {/* <Form.Item {...tailLayout} name="remember" valuePropName="checked">
        <Checkbox>Remember me</Checkbox>
      </Form.Item> */}

      {incorrect ? <p>Usuario y/o contrasena incorrectos</p> : null}

      <Form.Item {...tailLayout}>
        <Button
          htmlType="submit"
          style={{ background: "none", border: "1px solid #9146F7" }}
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  ) : (
    <Layout>
      <Header className="header" style={{ height: "12vh" }}>
        <img src={LogoQuilmes} className="logo" alt="Logo de Quilmes" />
        <img src={LogoItesa} className="logo" alt="Logo de Itesa" />
      </Header>
      <Layout style={{ minHeight: "88vh" }}>
        <Sider width={250}>
          <Menu
            onClick={handleClick}
            mode="inline"
            defaultSelectedKeys={["trivias"]}
            defaultOpenKeys={["sub1"]}
            style={{ height: "100%", borderRight: 0 }}
          >
            <Menu.Item key="trivias">Trivias</Menu.Item>
            <SubMenu title="Balón de Potrero">
              <Menu.Item key="meses">Meses</Menu.Item>
              <Menu.Item key="jugadores">Jugadores</Menu.Item>
              <Menu.Item key="success">Success Text</Menu.Item>
            </SubMenu>
            <Button
              style={{
                background: "none",
                border: "1px solid #9146F7",
                display: "flex",
                flexDirection: "row-reverse",
                margin: "20px auto",
              }}
              onClick={() => {
                handleLogout();
              }}
            >
              SALIR
            </Button>
          </Menu>
        </Sider>

        <Layout style={{ padding: "0 24px 24px" }}>
          <Content
            className="site-layout-background"
            style={{
              margin: 0,
              padding: 24,
              minHeight: 280,
            }}
          >
            {openMenuCategory === "trivias" && <Trivias />}
            {openMenuCategory === "meses" && (
              <Months
                months={months}
                setSelectedItem={setSelectedItem}
                setShowNewMonthModal={setShowNewMonthModal}
                setShowEditMonthModal={setShowEditMonthModal}
              />
            )}
            {openMenuCategory === "jugadores" && (
              <Players
                setSelectedItem={setSelectedItem}
                forcePlayersUpdate={forcePlayersUpdate}
                setForcePlayersUpdate={setForcePlayersUpdate}
                setShowNewPlayerModal={setShowNewPlayerModal}
                setShowEditPlayerModal={setShowEditPlayerModal}
              />
            )}

            {openMenuCategory === "success" && (
              <SuccessText
                setEditSuccess={setEditSuccess}
                setSelectItem={setSelectItem}
              />
            )}
          </Content>
          <Modal
            centered
            closeIcon={<CloseCircleOutlined style={{ fontSize: "2rem" }} />}
            visible={showEditMonthModal}
            onOk={() => setShowEditMonthModal(false)}
            onCancel={() => setShowEditMonthModal(false)}
            footer={null}
            width={900}
            wrapClassName={"wrapContainerModal"}
          >
            <EditMonth
              selectedItem={selectedItem}
              visible={showEditMonthModal}
              setModal={setShowEditMonthModal}
              setForceMonthsUpdate={setForceMonthsUpdate}
              forceMonthsUpdate={forceMonthsUpdate}
            />
          </Modal>
          <Modal
            centered
            closeIcon={<CloseCircleOutlined style={{ fontSize: "2rem" }} />}
            visible={showNewMonthModal}
            onOk={() => setShowNewMonthModal(false)}
            onCancel={() => setShowNewMonthModal(false)}
            footer={null}
            width={900}
            wrapClassName={"wrapContainerModal"}
          >
            <NewMonth
              selectedItem={selectedItem}
              visible={showNewMonthModal}
              setModal={setShowNewMonthModal}
              setForceMonthsUpdate={setForceMonthsUpdate}
              forceMonthsUpdate={forceMonthsUpdate}
            />
          </Modal>
          <Modal
            centered
            closeIcon={<CloseCircleOutlined style={{ fontSize: "2rem" }} />}
            visible={showEditPlayerModal}
            onOk={() => setShowEditPlayerModal(false)}
            onCancel={() => setShowEditPlayerModal(false)}
            footer={null}
            width={900}
            wrapClassName={"wrapContainerModal"}
          >
            <EditPlayer
              months={months}
              selectedItem={selectedItem}
              visible={showEditPlayerModal}
              setModal={setShowEditPlayerModal}
              setForcePlayersUpdate={setForcePlayersUpdate}
              forcePlayersUpdate={forcePlayersUpdate}
            />
          </Modal>
          <Modal
            centered
            closeIcon={<CloseCircleOutlined style={{ fontSize: "2rem" }} />}
            visible={showNewPlayerModal}
            onOk={() => setShowNewPlayerModal(false)}
            onCancel={() => setShowNewPlayerModal(false)}
            footer={null}
            width={900}
            wrapClassName={"wrapContainerModal"}
          >
            <NewPlayer
              months={months}
              selectedItem={selectedItem}
              visible={showNewPlayerModal}
              setModal={setShowNewPlayerModal}
              setForcePlayersUpdate={setForcePlayersUpdate}
              forcePlayersUpdate={forcePlayersUpdate}
            />
          </Modal>
          <Modal
            centered
            closeIcon={<CloseCircleOutlined style={{ fontSize: "2rem" }} />}
            visible={editSuccess}
            onOk={() => setEditSuccess(false)}
            onCancel={() => setEditSuccess(false)}
            footer={null}
            width={900}
            wrapClassName={"wrapContainerModal"}
          >
            <EditSuccess
              selectItem={selectItem}
              setModal={setEditSuccess}
            />
          </Modal>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default App;
