import React, { useState, useEffect } from "react";
import { Input, Form, Select, Upload, Modal, Button, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import styles from "./index.module.scss";
import firebase from "../../firebase.js";

const { Option } = Select;

const EditPlayer = ({ selectItem, setModal }) => {
  const [error, setError] = useState("");
  const [formDisabled, setFormDisabled] = useState(true);

  const [textos, setTextos] = useState({
    normalText: "",
    goldText: "",
    link: "",
    who:""
  });

  const handleChange = ({ target }) => {
    const { value, name } = target;
    return setTextos({ ...textos, [name]: value });
  };


  useEffect(() => {
    setTextos({
      normalText: selectItem.normalText,
      goldText: selectItem.goldText,
      link: selectItem.link,
      who: selectItem.who,
    });
  }, []);

  useEffect(() => {
    validateSubmit();
  }, [textos.normalText, textos.goldText, textos.link]);

  const validateSubmit = () => {
    let { normalText, goldText, link } = textos;
    if (normalText && goldText && link) {
      setFormDisabled(false);
    } else {
      setFormDisabled(true);
    }
  };

  const handleSubmit = (player) => {
    console.log("ola");
    firebase
      .firestore()
      .collection("success-text")
      .doc(selectItem.id)
      .update({ ...textos })
      .then(setModal(false));
  };

  return (
    <div>
      <div className={`${styles.pageHeader} ${styles.modalHeader}`}>
        <span className={styles.title}>Editar texto</span>
      </div>
      <div className={styles.modalRow}>
        <div className={styles.modalInputContainer}>
          <p className={styles.modalInputLabel}>1ER TEXTO</p>
          <Form.Item>
            <Input
              value={textos.normalText}
              name="normalText"
              onChange={handleChange}
            />
          </Form.Item>
          <p className={styles.modalInputLabel}>2DO TEXTO (EN NEGRITA)</p>
          <Form.Item>
            <Input
              value={textos.goldText}
              name="goldText"
              onChange={handleChange}
            />
          </Form.Item>
          <p className={styles.modalInputLabel}>LINK</p>
          <Form.Item>
            <Input value={textos.link} name="link" onChange={handleChange} />
          </Form.Item>
          <p className={styles.modalInputLabel}>3ER TEXTO </p>
          <Form.Item>
            <Input
              value={textos.who}
              name="who"
              onChange={handleChange}
            />
          </Form.Item>
        </div>
      </div>

      {/* error */}
      <div className={"ContainerMessageCantSlides"}>
        <p className={"messageCantSlide"}>{error}</p>
      </div>

      <Button
        onClick={handleSubmit}
        disabled={formDisabled}
        className={"sendButton"}
        type="primary"
        block
      >
        EDITAR
      </Button>
    </div>
  );
};

export default EditPlayer;
